import CryptoJS from 'crypto-js';
import Swal from "sweetalert2";

// Default export
const constant = {
  // API_BASE_URL: 'http://127.0.0.1:8000/api/',
  API_BASE_URL: 'https://bkeye.c9lab.com/api/'
};

// Utility function export
export function encryptToken(token) {
  const key = 'enc';
  return CryptoJS.AES.encrypt(token, key).toString();
};

export function decryptToken(encryptedToken,key) {
  const bytes = CryptoJS.AES.decrypt(encryptedToken, key);
  const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
  return decryptedToken;
};


export const response_message = "No Data Found";
export default constant;


// custom toast messages for success and error 
export function toastMessage(icon,message) {
  Swal.fire({
    toast: true,
    icon: icon,
    title: message,
    position: "top-right",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
    
  });
}
